const localhost = "http://localhost:4000/api";
const apiUrl = "https://cytapi.ap-south-1.elasticbeanstalk.com/api";
export const loginUrl = `${localhost}/auth/login`;
export const threapistRegistrationUrl = `${apiUrl}/therapist-registeration`;
export const registerUrl = `${localhost}/register`;
export const sendOtpUrl = `${localhost}/send-otp`;
export const sendForgotPasswordOtpUrl = `${localhost}/send-forgot-password-otp`;
export const verifyOtpUrl = `${localhost}/verify-otp`;
export const resetpasswordpUrl = `${localhost}/reset-password`;
export const getUserUrl = `${localhost}/get-user`;
export const getTherapists = `${localhost}/get-therapists`;
