export default function Content() {
  return (
    <div className="rbt-article-content-wrapper">
      <div className="content">
        
        <h4>Why Join us</h4>
        <ol>
          <li>
          <b>Profile Management Made Easy:</b> Access our intuitive therapist dashboard to effortlessly manage your profile, 
          update information, and showcase your expertise.
          </li>
          <li>
          <b>Streamlined Booking Process:</b> Simplify appointment scheduling with clients through our platform, reducing administrative 
          hassle and maximizing your time for what matters most-therapy.
          </li>
          <li>
          <b>Marketing Support:</b> Benefit from our marketing initiatives to boost your visibility and attract more clients. 
          We're committed to helping you grow your practice.
          </li>
          <li>
          <b>Professional Development:</b> Enjoy access to a wide range of events and workshops organized by Choose Your Therapist LLP. Stay updated with 
          the latest trends and techniques in mental health therapy.
          </li>
          
        </ol>
        <h4>Requirements</h4>
        <ol>
          <li>
          Therapists must have a valid license issued by a recognized regulatory body in India, such as: <br/>
          Rehabilitation Council of India (RCI) certification for Clinical Psychologists.<br/>
          License for Psychiatrists from the Medical Council of India (MCI) or relevant State Medical Council.
<br/>Licensed Counselors with certification from recognized institutions.

          </li>
          <li>
          Therapists must possess a minimum of a Master's degree in Psychology, Counseling, Psychiatry,
           or a related field from a recognized university.
          </li>
          <li>
          Membership with professional bodies such as the Indian Association of Clinical Psychologists (IACP), Indian Psychiatric Society 
          (IPS), or similar organizations is preferred.
          </li>
          <li>
          Therapists must have a minimum of 3 years of experience in providing therapy for adults, couples, and/or teens.
          </li>
          <li>
          Experience in specific therapeutic areas such as Cognitive Behavioral Therapy (CBT), Dialectical Behavior Therapy (DBT), Family Therapy, or any other relevant modalities is preferred.

          </li>
          <li>
          Therapists must have access to a desktop or laptop computer with a reliable internet connection and a functional webcam to conduct online therapy sessions.
          </li>
          <li>
          Therapists must currently reside in India.
          </li>
          <li>
          Proficiency in English and at least one regional language to cater to a diverse client base.
          </li>
          <li>
          Willingness to offer flexible hours, including evenings and weekends, to accommodate clients' schedules.
          </li>
          <p><b>Note:</b>Interns or those requiring supervision to provide therapy services cannot join the Choose Your Therapist LLP platform at this time. Therapists on our platform are not employees of Choose Your Therapist LLP but act as independent providers.</p>
        </ol>
        <h4>How to Join</h4>
        <ol>
          <li>
          Submit Resume
          </li>
          <li>
            Approval for Profile within 14 days  
          </li>
          <li>
            Create Profile
          </li>
          <li>
            Activation 
          </li>
        </ol>
      </div>
    </div>
  );
}
