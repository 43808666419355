export default function PlansHeader() {
  return (
    <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1 mt--60">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="breadcrumb-inner text-center">
            <h2 className="title">You deserve <span className="theme-gradient">peace of mind.</span> </h2>
            <ul className="page-list">
             
              
              <li className="rbt-breadcrumb-item active">
              How can we support you?
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
